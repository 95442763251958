import * as React from 'react';
import tw from 'twin.macro';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { models } from '../../models';
import { seoData } from '../data/seo';
import { defaultLangKey } from '../data/languages';
import Title from '../components/title';
import ContentWidth from '../components/contentWidth';

type Props = {
  location: Location;
};

function PrivacyPolicy({ location }: Props) {
  return (
    <Layout
      lang={defaultLangKey}
      location={location}
      pathnameWithoutLang={models.pages!.privacyPolicy.path}
    >
      <SEO
        featuredImage={seoData.privacyPolicy.featuredImage?.[defaultLangKey]}
        title={seoData.privacyPolicy.title[defaultLangKey]}
        description={seoData.privacyPolicy.description[defaultLangKey]}
        lang={defaultLangKey}
        pathname={location.pathname}
      />
      <ContentWidth>
        <Title title={`プライバシーポリシー`} />
        <div css={tw`mt-12`}>
          <p css={tw`leading-relaxed text-sm`}>
            当方運営ウェブサイト、アメリズム（以下「本サイト」といいます）をご利用いただく
            方の個人情報（個人情報の保護に関する法律（以下「個人情報保護法」といいます）第
            2条1項と同様の定義とします。）に関して、本サイト運営に携わる全員にプライバシ
            ーポリシーの重要性を認識させ、
            個人情報保護法に従って、お預かりした個人情報を
            適切に取り扱い、お客様に更なる信頼性と安心感をご提供できるように努めて参ります
            。
          </p>
          <h3 css={tw`text-lg font-bold my-3 leading-loose`}>
            [1.個人情報の取得]
          </h3>
          <p css={tw`leading-relaxed text-sm`}>
            当方は、偽りその他不正の手段によらず適正に個人情報を取得致します。
          </p>
          <h3 css={tw`text-lg font-bold my-3 leading-loose`}>
            [2.個人情報の利用目的]
          </h3>
          <p css={tw`leading-relaxed text-sm`}>
            (1)
            当方は、本サイト上で提供するサービス（以下「本サービス」といいます）をご利
            用いただく方の個人情報について、以下の利用目的の達成に必要な範囲内で利用致しま
            す。以下に定めのない目的で個人情報を利用する場合、あらかじめご本人の同意を得た
            上で行ないます。
          </p>
          <p css={tw`leading-relaxed text-sm`}>
            利用者の本人確認及び利用者向けの本サービスの提供
          </p>
          <p css={tw`leading-relaxed text-sm`}>
            本サービスへの各種お問い合わせ、ご意見、留学カウンセリングの対応
            留学契約履行、それに伴う各種手配
          </p>
          <p css={tw`leading-relaxed text-sm`}>依頼された海外渡航準備</p>
          <p css={tw`leading-relaxed text-sm`}>
            海外の最新情報、留学や海外生活に関する情報提供
          </p>
          <p css={tw`leading-relaxed text-sm`}>
            商用・転用目的での各種申込行為、各種多重申込、権利譲渡、虚偽情報登録などの調査
            と、それに基づく当該申込内容の詳細確認
          </p>
          <p css={tw`leading-relaxed text-sm`}>
            その他上記の利用目的に関連又は付随する目的
          </p>
          <p css={tw`leading-relaxed text-sm`}>
            (2)
            当方は、上記以外に、本サービスに関連して取得した利用者のログ情報（お客様が
            本サービス等を利用した際に自動で生成、保存されるIP
            アドレス、ブラウザ種類、ブラ
            ウザ言語等の情報）、Cookieおよび匿名ID等について、個人を識別できない形式に加工
            した上で、統計データの作成、または、当方もしくは第三者の広告の配信もしくは表示
            のために利用することがあります。
          </p>
          <h3 css={tw`text-lg font-bold my-3 leading-loose`}>
            [3.個人情報の安全管理]
          </h3>
          <p css={tw`leading-relaxed text-sm`}>
            当方は、取り扱う個人情報の漏洩、滅失またはき損の防止その他の個人情報の安全管理
            のために必要かつ適切な措置を講じます。情報の保管は、当方サーバーにて管理され、
            適正なセキュリティを施しています。また当方外に個人情報を持ち出すことなく当方内
            のみで安全に管理します。
          </p>
          <h3 css={tw`text-lg font-bold my-3 leading-loose`}>
            [4.個人情報の委託]
          </h3>
          <p css={tw`leading-relaxed text-sm`}>
            当方は、個人情報の取り扱いの全部または一部を第三者に委託する場合は、当該第三者
            について厳正な調査を行い、取り扱いを委託された個人情報の安全管理が図られるよう
            当該第三者に対する必要かつ適切な監督を行います。
          </p>
          <h3 css={tw`text-lg font-bold my-3 leading-loose`}>
            [5.個人情報の第三者提供]
          </h3>
          <p css={tw`leading-relaxed text-sm`}>
            当方が保有する個人情報は、あらかじめ利用者の同意を得ることなく第三者に提供しま
            せん。ただし、次に掲げる場合は、この限りではありません。
            当方が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委
            託する場合
          </p>
          <p css={tw`leading-relaxed text-sm`}>
            その他、個人情報保護法その他の法令で認められる場合
          </p>
          <h3 css={tw`text-lg font-bold my-3 leading-loose`}>
            [6.個人情報の取り扱いの改善・見直し]
          </h3>
          <p css={tw`leading-relaxed text-sm`}>
            当方は、個人情報の取扱いの全部又は一部を第三者に委託する場合、その適格性を十分
            に審査し、その取扱いを委託された個人情報の安全管理が図られるよう、委託を受けた
            者に対する必要かつ適切な監督を行うこととします。
          </p>
          <h3 css={tw`text-lg font-bold my-3 leading-loose`}>
            [７.個人情報の廃棄]
          </h3>
          <p css={tw`leading-relaxed text-sm`}>
            当方は、個人情報の利用目的に照らしその必要性が失われたときは、個人情報を消去又
            は廃棄するものとし、当該消去及び廃棄は、外部流失等の危険を防止するために必要か
            つ適切な方法により、業務の遂行上必要な限りにおいて行います。
          </p>
        </div>
      </ContentWidth>
    </Layout>
  );
}

export default PrivacyPolicy;
